@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
@font-face {
    font-family: dorldor-icons;
    src: url(assets/fonts/dorldor-icons.ttf) format("truetype");
    /* ,url(assets/dorldor-icons.svg?v16do3#dorldor) format("svg"); */
    font-weight: 400;
    font-style: normal
}

[class^=d-icon-],[class*=" d-icon-"] {
    font-family: dorldor-icons!important;
    font-style: normal;
    font-weight: 400;
    font-size: inherit;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.d-icon-contract:before {
    content: "\e900"
}

.d-icon-doctor:before {
    content: "\e901"
}

.d-icon-family:before {
    content: "\e903"
}

.d-icon-friends-talking:before {
    content: "\e90b"
}

.d-icon-frontal-taxi-cab:before {
    content: "\e90c"
}

.d-icon-freinds:before {
    content: "\e90d"
}

.d-icon-login:before {
    content: "\e90e"
}

.d-icon-old-man:before {
    content: "\e90f"
}

.d-icon-party:before {
    content: "\e910"
}

.d-icon-placeholder:before {
    content: "\e911"
}

.d-icon-prayer:before {
    content: "\e912"
}

.d-icon-settings:before {
    content: "\e913"
}

.d-icon-delivery:before {
    content: "\e902"
}

.d-icon-distance:before {
    content: "\e914"
}

.d-icon-home:before {
    content: "\e915"
}

.d-icon-wheel-chair:before {
    content: "\e916"
}

.d-icon-social:before {
    content: "s"
}

.d-icon-disability:before {
    content: "d"
}

.d-icon-public-transport:before {
    content: "p"
}

.d-icon-medical:before {
    content: "m"
}

.d-icon-help-2:before {
    content: "\e904"
}

.d-icon-public-transport2:before {
    content: "\e905"
}

.d-icon-walk-indepndent:before {
    content: "\e906"
}

.d-icon-pedestrian-walking:before {
    content: "\e907"
}

.d-icon-shopping:before {
    content: "\e908"
}

.d-icon-car:before {
    content: "\e909"
}

.d-icon-walk-support:before {
    content: "\e90a"
}